<template>
  <vue100vh
    :css="{ height: '100rvh' }"
    class="relative gap-4 md:gap-6 grid grid-rows-1/auto px-3 pt-3 pb-4 md:p-6 w-full select-none"
    :draggable="false"
  >
    <div class="flex h-full w-full items-center justify-center">
      <div>
        <h1 class="text-6xl">Page not found.</h1>
      </div>
    </div>
    <div>
      <router-link
        to="/"
        class="inline-block bg-white text-gray-900 font-bold px-6 py-3 text-xl rounded-md border-4 border-white m-4 focus:outline-none appearance-none focus:shadow-outline"
      >
        Return to homepage
      </router-link>
    </div>
  </vue100vh>
</template>

<script>
import vue100vh from 'vue-100vh-vp'

export default {
  name: 'NotFound',
  components: {
    vue100vh,
  },
}
</script>

<style lang="postcss"></style>
